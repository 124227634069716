import $ from 'jquery';
import 'nouislider/distribute/nouislider.js';
import 'jquery-ujs';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick.js';
import 'imagesloaded';
import 'parsleyjs/dist/parsley';
import 'unishop';

window.$ = jQuery;
window.jQuery = jQuery; 
window.Parsley = Parsley; 

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );