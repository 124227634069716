import Parsley from 'parsleyjs';

$(() => {

  $(".datepicker").datepicker({ minDate: new Date() });

  $("body").on('click', '.add-to-cart', function(event){
    const $this = $(event.currentTarget);
    if (!$this.hasClass("in-cart")) {
      event.preventDefault();
      const pid = $this.data('id');
      $.post('/order_items', {
        "order_item[product_id]": pid,
        "order_item[quantity]": 1
      },
        function(data) {
          $(".cart").replaceWith(data);
          $(`.add-to-cart[data-id=${pid}]`).removeClass('btn-outline-primary').addClass('btn-success').addClass('in-cart').attr("href", '/cart').off().html(`<i class='fa fa-check'></i>&nbsp; в корзине`);
        }
      );
    }
  });
 
  $(".payment-methods a").click( event => {
    const $this = $(event.currentTarget);
    $(".payment-methods a").removeClass('active');
    $this.addClass('active');
    $("#order_payment_method").val($this.data('payment'));
  });

  $("#cart").on('click', '.qbutton', event => {
    const $this = $(event.currentTarget);
    const pid = $this.data("product-id");
    const action = $this.data("action");
    const q = $this.parent().parent().find('.cart-quantity').val();
    const $tr = $(`tr[data-product-id=${pid}]`);
    $tr.css('opacity', 0.6);
    $.ajax(`/order_items/${pid}.js?q=${q}&act=${action}`, {
      type: 'PATCH',
      success(data) {
        return $tr.css('opacity', 1);
      }
    }
    );
    return false;
  });


  $("#cart").on('change', '.cart-quantity', event => {
    const $this = $(event.currentTarget);
    const pid = $this.data("product-id");
    const q = $this.val();
    const $tr = $(`tr[data-product-id=${pid}]`);
    $tr.css('opacity', 0.6);
    return $.ajax(`/order_items/${pid}.js?q=${q}`, {
      type: 'PATCH',
      success(data) {
        return $tr.css('opacity', 1);
      }
    }
    );
  });
});
