const isInViewport = (elem) => {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const fetchPage = function(page, append) {
  const url = $('#products_search').attr('action');
  if (page) { 
    $('#search-results').css("opacity", '0.6');
    if (append) {
      $('.next-page-wrapper a').text("Загружаем...").removeClass('nextpage');
    }
    else
    {
      $( 'html' ).velocity( 'scroll', {
        offset: $('.shop-toolbar').offset().top,
        duration: 1200,
        easing: 'easeOutExpo',
        mobileHA: false
      } );
    }
    let pars = $('#products_search').serialize();
    if (pars.indexOf('&') !== -1) { 
      pars = pars + '&paginate=' + (append ? 'next' : 'true') + '&page=' + page;
    } else {
      pars = pars + '?paginate=' + (append ? 'next' : 'true') + '&page=' + page;
    }
    if (url.split('/')[3] === 'search') {
      $.get(url + '.js', pars, () => { 
        $('#search-results').css("opacity", '1');
      });
    } else {
      $.get(url.replace('/search','') + '.js', pars, () =>{
        $('#search-results').css("opacity", '1');
      });
    }
  }
};

$(document).on('click', '.nextpage', function(e) {
  const page = $('.next-page-wrapper a').data('page');
  fetchPage(page, true);
  e.preventDefault();
});

$(document).on('click', '.pagination a', function(e) {
  const $this = $(this);
  var page = 1;
  if ($this.attr('href').split('page=').length > 1) {
    page = parseInt($(this).attr('href').split('page=')[1]);  
  }
  fetchPage(page, false);
  e.preventDefault();
});


$(document).on('click', '.remove-filter', function(e) {
  const key = $(this).data('filter');
  $(`input[data-filter=${key}]`).val(''); 
  $(`input[data-filter=${key}]`).prop('checked', false);
  filterProducts();
  e.preventDefault();
});


jQuery(function() {
  
  // if ($('.pagination').length) {
  //   const $window = $(window);
  //   $window.scroll(function() {
  //     if (isInViewport($('.pagination')[0])) {
  //       fetchPage();
  //     }
  //   });
  //   $window.scroll();
  // }

  adjustFilters();
});  



const delay = (time, fn) => setTimeout(fn, time);
  
let keyup_timeout;
$(document).on('keyup', '.keyup-filter', function() {
  if (keyup_timeout !== undefined) {
    clearTimeout(keyup_timeout);
  }
  keyup_timeout = delay(1000, function() {
    keyup_timeout = undefined;
    filterProducts();
  });
});

  
$(document).on('change', '#sorting', function() {
  const v = $(this).val();
  $("#sort").val(v.split('-')[0]);
  $("#sort_direction").val(v.split('-')[1]);
  filterProducts();
});



$(document).on('change', '.sel-filter', () => filterProducts());

$(document).on('change', '.range-filter', () => filterProducts());


$(document).on('change', '.checkbox-group', function() {
  const val = $(this).prop('checked');
  const id = $(this).attr('id');
  $(`.checkbox-filter[data-parent='${id}']`).prop('checked', val);
  filterProducts();
});


$(document).on('change', '.subregion', function() {
  const parent = $(this).data('parent');
  $('#${parent}').prop('checked', $(`.subregion[data-parent='${parent}']:checked`).length === $(`.subregion[data-parent='${parent}']`).length);
  filterProducts();
});



$(document).on('change', '.checkbox-filter', function() {
  const $this = $(this);
  if ($this.hasClass('region')) {
    $(`.subregion[data-parent='${$this.attr('id')}']`).prop('checked', $(this).prop('checked') );
  }
  filterProducts();
});


var filterProducts = function() {
  $(".loader-inner").show();
  $.get($('#products_search').attr('action') + '.js', $('#products_search').serialize(), () => {
    $(".loader-inner").hide();
  });
  adjustFilters();
};
  
const clearFilters = function() {
  $('.sel-filter').val('');
  $('.range-filter').val('');
  if ($('.select2').length) { 
    $('.select2').select2('val','');
  }
  $('.keyup-filter').val('');
  $('.checkbox-filter').attr('checked', false);
};
  
var adjustFilters = () =>
  $('.checkbox-group').each(function() {
    $(this).prop('checked', $(`.checkbox-filter[data-parent='${$(this).attr('id')}']:checked`).length === $(this).data("size") );
  }) 
;
  
$(document).on('click', '.clear-filters', function(e) {
  clearFilters();
  filterProducts();
  e.preventDefault();
});