import './js/modernizr.min';
import 'jquery-ui'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/i18n/datepicker-ru'
import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/datepicker.css'
import 'jquery-ui/themes/base/theme.css'
import 'select2'
import 'select2/dist/css/select2.css'

import './css/pe-icons.css';
import './css/card.min.css';
import './css/feather.min.css';
import './css/futura.css';
import 'socicon/css/socicon.css';
import './img/404_art.jpg';
import './img/credit-cards.png';
import './img/default-skin.svg';
import './img/loading.gif';
import './img/map-marker.png';
import './img/payment_methods.png';
import './img/preloader.gif';
import './img/finewine_round.png';
import './img/finewine.png';
import './img/bottle.png';
import './img/deliver.png';
import './img/moneyback.png';
import './img/choice.png';
import './img/payment.png';
import './img/fwlogo.jpg';
import './img/truck.jpg';
import './img/truck_cdek.jpg';
import './img/categories/glass1.png';
import './img/categories/glass2.jpg';
import './img/categories/glass3.png';
import './img/categories/cabinets1.jpg';
import './img/categories/cabinets2.jpg';
import './img/categories/cabinets3.jpg';
import './img/categories/pans1.jpg';
import './img/categories/pans2.jpg';
import './img/categories/pans3.jpg';

import './img/landings/nez/le_nez_du_vin_54_thumb.png';
import './img/landings/nez/le_nez_du_vin_54.png';
import './img/landings/nez/le_nez_du_whisky_thumb.jpg';
import './img/landings/nez/le_nez_du_whisky.jpg';
import './img/landings/nez/le_nez_du_vin_24_thumb.png';
import './img/landings/nez/le_nez_du_vin_24.png';
import './img/landings/nez/le_nez_du_vin_rouge_thumb.png';
import './img/landings/nez/le_nez_du_vin_rouge.png';
import './img/landings/nez/le_nez_du_vin_blanc_thumb.png';
import './img/landings/nez/le_nez_du_vin_blanc.png';
import './img/landings/nez/le_nez_du_vin_oak_thumb.png';
import './img/landings/nez/le_nez_du_vin_oak.png';
import './img/landings/nez/le_nez_du_vin_defauts_thumb.png';
import './img/landings/nez/le_nez_du_vin_defauts.png';
import './img/landings/nez/le_nez_du_vin_mini_thumb.png';
import './img/landings/nez/le_nez_du_vin_mini.png';
import './img/landings/nez/le_nez_du_cafe_thumb.png';



import './img/categories/pans3.jpg';
import './img/categories/pans3.jpg';
import './img/whatsapp.png';
import './img/tg.png';

import './css/styles.css';
import 'velocity-animate/velocity'
import './js/scripts';
import './js/products';
import './js/cart';
import './js/geolocate';
import './css/custom.css';
