$(document).ready(function() {
   if ($("#current_city").text()=='не выбран' && typeof ymaps !== 'undefined')
   {
     ymaps.ready(initGeolocation); 
   }
});

function initGeolocation() {

    // Данные о местоположении, определённом по IP
    var geolocation = ymaps.geolocation;
    $.post('/set_city', {city: geolocation.city, region: geolocation.region, country: geolocation.country}, function(data) {
     
    });
}
